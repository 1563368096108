import { Tag } from 'constants/TagMfe';
import { Title } from 'constants/Title';
import { Type } from 'constants/TypeMfe';
import { Uri } from 'constants/Uri';
import { useHashPrevention } from 'hooks/useHashPrevention';
import { IFragmentUserData, IMicrofrontendRelease } from 'interfaces';
import { useAuth } from 'oidc-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { InjectMicrofrontEnd } from 'services/MicrofrontEnd';
import { getUnsupportedPack, redirectUnsupportedPack } from 'utils/ConfigHandlers';

const PackManager: FC = () => {
  const [data, setData] = useState<IFragmentUserData>();
  const auth = useAuth();
  const unsupportedPack = getUnsupportedPack();
  useHashPrevention(Uri.PACK_MANAGER);

  const setReleaseUri = useCallback(
    async (type: string) => {
      let uri = process.env.REACT_APP_SRC_PACK_MANAGER;
      if (!window.location.href.includes('localhost')) {
        const response = await fetch(`${process.env.REACT_APP_SRC_SERVER}${Uri.RELEASE}${type}`, {
          headers: new Headers({
            'X-API-Key': process.env.REACT_APP_SRC_TOKEN,
          } as HeadersInit),
        });
        uri = response.ok ? ((await response.json()) as IMicrofrontendRelease).uri : uri;
      }

      const urlScript = `${uri}main.js`;
      const urlSettings = `${uri}settings.json`;

      setData({
        uri: uri,
        urlScript: urlScript,
        access_token: auth.userData?.access_token,
        profile: auth.userData?.profile,
        settingsSource: urlSettings,
      } as IFragmentUserData);
    },
    [auth.userData],
  );

  useEffect(() => {
    setReleaseUri(Type.PACK_MANAGER);
  }, [setReleaseUri]);

  const redirect = useCallback(() => {
    document.title = Title.PACK_MANAGER;
    if (data) {
      InjectMicrofrontEnd<IFragmentUserData>(Tag.CONTAINER_MFE, data.urlScript ?? '', Tag.PACK_MANAGER, data);
    }
  }, [data]);

  useEffect(() => {
    if (auth.userData && unsupportedPack == undefined) {
      redirect();
    } else if (unsupportedPack) {
      redirectUnsupportedPack(unsupportedPack);
    }
  }, [auth.userData, redirect, unsupportedPack]);

  return <div id={Tag.CONTAINER_MFE} />;
};

export default PackManager;
