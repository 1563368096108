export function InjectMicrofrontEnd<TUserData>(
  idContainer: string,
  urlScript: string,
  tagMfe: string,
  userData: TUserData,
) {
  const idScriptImport = `${idContainer}-script-mfe`;
  if (!window.document.getElementById(idScriptImport)) {
    const script = document.createElement('script');
    script.id = idScriptImport;
    script.src = urlScript;
    document.body.appendChild(script);
  }

  const container = window.document.getElementById(idContainer);
  const idMfe = `${container?.id}-fragment`;

  if (!container?.ownerDocument.getElementById(idMfe)) {
    const mfe = document.createElement(tagMfe);
    mfe.id = idMfe;
    mfe.setAttribute('userdata', JSON.stringify(userData));
    container?.appendChild(mfe);
  }
}
