export const initializeSegment = () => {
  if (!process.env.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY === '') return;

  analytics.load(process.env.REACT_APP_ANALYTICS_KEY);
  analytics.page();
};

export const identify = (email: string) => {
  analytics.identify(email);
};

export const track = (event: string, payload?: any) => {
  analytics.track(event, payload);
};

export const trackWithCallback = (event: string, payload?: any, callback?: (() => void) | undefined) => {
  if (callback) analytics.track(event, payload, callback);
  else track(event, payload);
};
