import { useHashPrevention } from 'hooks/useHashPrevention';
import { useLocation } from 'react-router-dom';
import { track } from 'services/Segment';
import { useEffect } from 'react';
import { BS_OPENED } from 'constants/TrackingEvents';
import { BLIPSHOP_PATH_NAME } from 'constants/ApplicationsPath';

const TrackingRoute: React.FC = () => {
  // It's necessary to prevent hash at URL
  useHashPrevention('/');

  const location = useLocation();
  const queryString = new URLSearchParams(location.search);
  const trackButtonValue = queryString.get('trackButtonClick');
  
  useEffect (()=> {
    (trackButtonValue === BLIPSHOP_PATH_NAME) && track(BS_OPENED);
    setTimeout(() => {
      window.location.replace(`/${trackButtonValue}`);
    }, 2300);

  },[trackButtonValue])

  return <span>Acessando . . .</span>;

};

export default TrackingRoute;
