import { UnsupportedPackConf } from 'typings/AppConfig';

const clearSlash = (str: string) => str.replace(/\//g, '');

export const getUnsupportedPack = () => {
  const unsupportedPackStr = process.env.REACT_APP_PACKS_NOT_SUPPORTED;

  if (!unsupportedPackStr) return;

  try {
    const unsupportedPacksConf: UnsupportedPackConf[] = JSON.parse(unsupportedPackStr);
    const urlParams = Array.from(new URLSearchParams(location.search).entries());

    if (typeof unsupportedPacksConf !== 'object' || !urlParams || urlParams.length <= 0) return;

    const unsupportedPack: UnsupportedPackConf | undefined = unsupportedPacksConf.find(
      (config: UnsupportedPackConf) => {
        const [paramKey, paramVal] = config?.packInfo;

        const searchResult = urlParams.find(UrlQueryParam => {
          const [queryKey, queryVal] = UrlQueryParam;

          return paramKey.toLowerCase() === queryKey.toLowerCase() && paramVal === queryVal;
        });

        return searchResult !== undefined;
      },
    );

    return unsupportedPack;
  } catch (e: any) {
    console.error(e);
  }
};

export const redirectUnsupportedPack = (unsupportedPack: UnsupportedPackConf) => {
  const pathname = window.location.pathname;
  try {
    if (unsupportedPack) {
      const redirect = unsupportedPack?.redirectInfo.find(info => clearSlash(info.route) === clearSlash(pathname));
      const { urlTo } = redirect || {};

      if (urlTo) {
        window.location.replace(urlTo);
      }
    }
  } catch (e: any) {
    console.error(e);
  }
};
