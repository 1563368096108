import { WebStorageStateStore } from 'oidc-client';

export const SCOPE = 'offline_access openid profile email api-payment-service.full_access';
export const MINUTES_TO_SECONDS = 60;
const currentDomain = `${window.location.origin}/`;
const clientSecrets = [
  '}A n2p,Y$:g^Rt61fI[0~3-Q2NDPo6aPMcu"OcaN"N<SY',
  " O/>+nW=x)~f|;1wH8J#=gZ4O.Wo^'],[@FL#pc@a>&gL",
  '"1a+/Bi^$"f?59FwodX5":WglnOHUA$fgrC8y8y)$A@1 % ',
  "{H!gD!$lTt(kgP6,}-<KY`Si,q_C{;m'>(>,.)(203L,",
];

export const getOidcBaseConfig = (urlRedirect: string) => {
  return {
    authority: process.env.REACT_APP_AUTHORITY,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: urlRedirect,
    responseType: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_ACNT_SCOPE,
    post_logout_redirect_uri: `${currentDomain}`,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    automaticSilentRenew: true,
    silent_redirect_uri: `${currentDomain}renew-callback.html`,
    accessTokenExpiringNotificationTime: parseInt(process.env.REACT_APP_TOKEN_EXPIRING_MIN || '5') * 60,
    clientSecret: clientSecrets[Math.floor(Math.random() * clientSecrets.length)],
  };
};
