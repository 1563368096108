import { getOidcBaseConfig } from 'constants/Oidc';
import { initUserManager } from 'oidc-react';

export const LogOut = async () => {
  const urlRedirect = encodeURI(window.location.href);
  const oidc = getOidcBaseConfig(urlRedirect);
  const userManager = initUserManager(oidc);

  await userManager.signoutRedirect();
  await userManager.clearStaleState();

  const urlLogout = `${process.env.REACT_APP_AUTHORITY}/logout/redirect?returnDesiredUrl=${urlRedirect}`;
  window.location.href = urlLogout;
};
